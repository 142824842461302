import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Input,
  InputLabel,
  Typography,
  withStyles
} from "@material-ui/core";

import { SearchAbbreviationCardStyle } from "./SearchAbbreviationCard.style";

class SearchAbbreviationCard extends Component {
  constructor(props) {
    super(props);

    this.handleOnChangeTextInput = this.handleOnChangeTextInput.bind(this);
    this.props.getViews();
  }

  handleOnChangeTextInput(event) {
    this.searchAbbreviations(event.target.value);
  }

  searchAbbreviations = q => {
    this.props.searchAbbreviations({
      ...this.props.search,
      q,
      from: null,
      to: null
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.searchCard}>
        <CardHeader title="Zoek en vind afko's" />
        <CardContent className={classes.compactLastCard}>
          {this.renderSearchInput()}
          {this.renderMostViewedLinks()}
          {this.renderFilters()}
        </CardContent>
      </Card>
    );
  }

  renderMostViewedLinks() {
    const { views, classes } = this.props;
    return (
      <span>
        <Typography variant="h6" className={classes.alignedWithLinks}>
          Veelgezochte afko's:
        </Typography>
        {Array.isArray(views) &&
          (views.length > 0 &&
            views.map((afko, i) => (
              <Button
                key={i}
                variant="text"
                classes={{ text: classes.mostViewedButton }}
                onClick={() => this.searchAbbreviations(afko.abbreviation)}
              >
                {afko.abbreviation}
              </Button>
            )))}
      </span>
    );
  }

  renderSearchInput() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth={true} className={classes.alignedWithLinks}>
        <InputLabel htmlFor="search-field" className={classes.inputLabel}>
          Zoek op afko
        </InputLabel>
        <Input
          autoFocus={true}
          fullWidth={false}
          classes={{
            root: classes.inputField,
            underline: classes.inputFieldUnderline
          }}
          id="search-field"
          onChange={this.handleOnChangeTextInput}
          value={this.props.search.q || ""}
        />
      </FormControl>
    );
  }

  renderFilters() {
    const { classes } = this.props;
    const SECTIONS = [
      { display: "A-E", from: "a", to: "ezzzzzzzzzz" },
      { display: "F-M", from: "f", to: "mzzzzzzzzzz" },
      { display: "N-T", from: "n", to: "tzzzzzzzzzz" },
      { display: "U-Z", from: "u", to: "zzzzzzzzzzz" }
    ];

    return (
      <span>
        <Typography variant="h6" className={classes.alignedWithLinks}>
          Browse afko's:
        </Typography>
        {SECTIONS.map((section, i, arr) => (
          <span key={i}>
            <Button
              text={section.display}
              variant="text"
              classes={{ text: classes.mostViewedButton }}
              onClick={() => this.handleFilterChanged(section.from, section.to)}
            >
              {section.display}
            </Button>
          </span>
        ))}
      </span>
    );
  }

  handleFilterChanged(from, to) {
    this.props.searchAbbreviations({
      ...this.props.search,
      q: "",
      from,
      to
    });
  }
}

export default withStyles(SearchAbbreviationCardStyle)(SearchAbbreviationCard);
