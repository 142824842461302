import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import * as actions from "../actions";

export function* getViews(action) {
  const { data, error } = yield call(api.getViews);
  yield put(actions.receiveViews({ data, error }));
}

export function* watchGetViews() {
  yield takeLatest(actions.GET_VIEWS, getViews);
}
