import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  MenuList
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { ROUTES } from "../../constants/routes";

class NavigationMenu extends Component {
  state = { drawer: false };

  toggleDrawer = () => {
    this.setState({ drawer: !this.state.drawer });
  };

  handleMenuClick = path => {
    this.props.history.push(path);
    this.toggleDrawer();
  };

  render() {
    return (
      <div>
        <IconButton
          color="inherit"
          onClick={this.toggleDrawer}
          data-cy="menu-button"
        >
          <MenuIcon />
        </IconButton>
        <Drawer open={this.state.drawer} onClose={this.toggleDrawer}>
          <MenuList>
            {Object.entries(ROUTES).map((item, i) => (
              <span key={i}>
                <MenuItem
                  onClick={() => this.handleMenuClick(item[1])}
                  data-cy={`route${item[1]}`}
                  style={{
                    fontSize: 14,
                    textTransform: "uppercase",
                    color: "#717171"
                  }}
                >
                  {item[0]}
                </MenuItem>
                <Divider />
              </span>
            ))}
          </MenuList>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(NavigationMenu);
