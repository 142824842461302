export const AppStyle = () => ({
  siteTitleGridItem: {
    marginLeft: "23px",
    marginRight: "12px"
  },
  siteTitle: {
    color: "#E17000",
    fontWeight: 400,
    fontSize: 35,
    marginRight: 10
  },
  tagLine: {
    color: "#717171",
    fontSize: 12,
    marginTop: -5
  },
  fixItemForIE: {
    flexBasis: "auto"
  },
  main: {
    flexBasis: "auto",
    marginTop: "26px"
  },
  footerText: {
    color: "#717171",
    fontSize: 16
  },
  noTopShadowAppBar: {
    height: "100%",
    width: "100%",
    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.14)"
  }
});
