import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import * as actions from "../actions";

export function* startGame(action) {
  const { data, error } = yield call(
    api.startGame,
    action.player,
    action.organizationId
  );
  yield put(actions.startedGame({ data, error }));
}

export function* watchStartGame() {
  yield takeLatest(actions.START_GAME, startGame);
}

export function* endGame(action) {
  const { data, error } = yield call(api.endGame, action.gameId);
  yield put(actions.endedGame({ data, error }));
  yield put(actions.getHighscores());
}

export function* watchEndGame() {
  yield takeLatest(actions.END_GAME, endGame);
}

export function* answerQuestion(action) {
  const { data, error } = yield call(
    api.answerQuestion,
    action.gameId,
    action.answer
  );
  yield put(actions.answeredQuestion({ data, error }));
}

export function* watchAnswerQuestion() {
  yield takeLatest(actions.ANSWER_QUESTION, answerQuestion);
}
