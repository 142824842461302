import { connect } from "react-redux";

import { getOrganizations } from "../../actions";

import GameContainer from "./GameContainer";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getOrganizations: () => dispatch(getOrganizations())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameContainer);
