import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  withWidth
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { isEqual } from "lodash";

import MoreInformationButton from "../shared/MoreInformationButton";
import { EMPTY_SEARCH } from "../../reducers/search";

import { AbbreviationsCardStyle } from "./AbbreviationsCard.style";

class AbbreviationsCard extends Component {
  constructor(props) {
    super(props);
    this.props.getOrganizations();
  }

  filterSearch = event => {
    this.props.searchAbbreviations({
      ...this.props.search,
      organization_id: event.target.value
    });
  };

  renderSearchResults() {
    const data = this.props.searchedAbbreviations;

    return (
      Array.isArray(data.results) &&
      (data.results.length > 0
        ? this.renderResults()
        : !isEqual(this.props.search, EMPTY_SEARCH) &&
          AbbreviationsCard.renderLinkToSubmit(false))
    );
  }

  static renderLinkToSubmit(hasResults) {
    return (
      <div>
        <Typography color="textSecondary">
          {hasResults
            ? "Staat jouw afko er niet bij?"
            : "Geen afko's gevonden."}{" "}
          Klik{" "}
          <Link color="secondary" href="/indienen">
            hier
          </Link>{" "}
          om je afko in te dienen.
        </Typography>
      </div>
    );
  }

  renderResults() {
    const { classes } = this.props;
    const data = this.props.searchedAbbreviations;
    return (
      <div>
        <Table>
          <TableBody>
            {data.results.map((afko, i) =>
              afko.definitions.map((definition, j) => (
                <TableRow data-cy="search-result-row" key={`row-${i}-${j}`}>
                  <TableCell
                    padding="none"
                    className={classes.abbreviationCell}
                  >
                    {afko.abbreviation}
                  </TableCell>
                  <TableCell
                    padding="dense"
                    className={classes.abbreviationCell}
                  >
                    -
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={classes.abbreviationCell}
                  >
                    {definition.fullText}
                    <Typography
                      variant="body1"
                      color="secondary"
                      className={classes.organizationNames}
                    >
                      {definition.organizations.map(org => org.name).join(", ")}
                      <MoreInformationButton url={definition.url} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {AbbreviationsCard.renderLinkToSubmit(true)}
      </div>
    );
  }

  renderOrganizationFilter() {
    const { organizations, classes } = this.props;
    return (
      <div>
        Betekenis
        <Typography color="textSecondary">Filter op organisatie: </Typography>
        <FormControl>
          <Select
            id="organization-id"
            name="organizationId"
            native={true}
            onChange={this.filterSearch}
            className={classes.organizationSelect}
          >
            <option value="">Allemaal</option>
            <option value="rijksbreed">Rijksbreed</option>
            {organizations &&
              organizations.map(org => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Card data-cy="abbreviations-card" className={classes.abbreviationsCard}>
        <CardHeader title={this.renderOrganizationFilter()} />
        <CardContent className={classes.compactCardContent}>
          {this.props.loading
            ? this.renderSpinner()
            : this.renderSearchResults()}
        </CardContent>
      </Card>
    );
  }

  renderSpinner() {
    const { classes } = this.props;
    return (
      <div className={classes.spinner}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
}

export default withStyles(AbbreviationsCardStyle)(
  withWidth()(AbbreviationsCard)
);
