export const HighScoresCardStyle = theme => ({
  highscores: {
    fontSize: 16,
    color: "#717171"
  },
  highscoresFirst: {
    fontSize: 20,
    color: "#717171"
  },
  highscoresSecond: {
    fontSize: 18,
    color: "#717171"
  },
  highscoresThird: {
    fontSize: 16,
    color: "#717171"
  },
  organization: {
    color: "#E17000",
    fontSize: 11
  },
  awardFirst: {
    color: "#FFC15A",
    height: 25,
    width: 27
  },
  awardSecond: {
    color: "#D1D1D1",
    height: 20,
    width: 22
  },
  awardThird: {
    color: "#D98D40",
    height: 17,
    width: 19
  },
  rank: {
    fontSize: 14
  },
  highscoresCard: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
      marginRight: 5
    }
  }
});
