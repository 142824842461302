export const ResultsCardStyle = theme => ({
  th: {
    fontSize: 14
  },
  correct: {
    fontSize: 20,
    color: "#519800"
  },
  incorrect: {
    fontSize: 20,
    color: "#C40000"
  },
  abbreviation: {
    fontSize: 20,
    color: "#717171"
  },
  organizationNames: {
    fontSize: 12
  },
  definition: {
    fontSize: 20,
    color: "#717171"
  },
  score: {
    fontSize: 16
  },
  answerRow: {
    verticalAlign: "top"
  },
  resultsCard: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
      marginRight: 5
    }
  }
});
