import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import * as actions from "../actions";

export function* getHighscores(action) {
  const { data, error } = yield call(api.getHighscores);
  yield put(actions.receiveHighscores({ data, error }));
}

export function* watchGetHighscores() {
  yield takeLatest(actions.GET_HIGHSCORES, getHighscores);
}
