import { START_LOADING, STOP_LOADING } from "../actions";

function loading(state = false, action) {
  if (action.type === START_LOADING) {
    return true;
  } else if (action.type === STOP_LOADING) {
    return false;
  }
  return state;
}

export default loading;
