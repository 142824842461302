import { RECEIVE_VIEWS } from "../actions";

function views(state = null, action) {
  if (action.type === RECEIVE_VIEWS) {
    state = action.data;
  }
  return state;
}

export default views;
