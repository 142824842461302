import { RECEIVE_AFKORTINGEN } from "../actions";

const INITIAL_STATE = {
  results: []
};

function searchedAbbreviations(state = INITIAL_STATE, action) {
  if (action.type === RECEIVE_AFKORTINGEN) {
    state = action.data;
  }
  return state;
}

export default searchedAbbreviations;
