import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withFormsy } from "formsy-react";

class AfkoTextField extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    return (
      <TextField
        className={this.props.className}
        autoFocus={this.props.autoFocus}
        id={this.props.id}
        error={!!this.props.getErrorMessage()}
        required={this.props.required}
        label={this.props.label}
        name={this.props.name}
        onChange={this.changeValue}
        value={this.props.getValue() || ""}
        helperText={this.props.getErrorMessage()}
      />
    );
  }
}

export default withFormsy(AfkoTextField);
