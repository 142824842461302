import { connect } from "react-redux";

import {
  getOrganizations,
  searchAbbreviations,
  updateSearch
} from "../../actions";

import AbbreviationCard from "./AbbreviationsCard";

const mapStateToProps = ({
  loading,
  search,
  searchedAbbreviations,
  organizations
}) => ({
  loading,
  search,
  searchedAbbreviations,
  organizations
});
const mapDispatchToProps = dispatch => ({
  getOrganizations: () => dispatch(getOrganizations()),
  searchAbbreviations: search => {
    dispatch(updateSearch(search));
    dispatch(searchAbbreviations(search));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbbreviationCard);
