import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import ReactiveSubmitAbbreviationCard from "./ReactiveSubmitAbbreviationCard";

class ContainerIndienen extends Component {
  render() {
    return (
      <Grid container justify="space-around" spacing={32}>
        <Grid item xs={12} md={3}>
          <ReactiveSubmitAbbreviationCard history={this.props.history} />
        </Grid>
      </Grid>
    );
  }
}

export default ContainerIndienen;
