import { Button, SvgIcon, withStyles } from "@material-ui/core";
import React, { Component } from "react";

import { ReactComponent as ExternalLinkIcon } from "./ExternalLink.svg";
import { MoreInformationButtonStyle } from "./MoreInformationButton.style";

class MoreInformationButton extends Component {
  render() {
    const { url, classes } = this.props;
    if (url) {
      return (
        <Button
          data-cy="more-information-link"
          size="small"
          href={url}
          target="_blank"
          className={classes.infoLink}
        >
          <SvgIcon className={classes.infoLinkIcon}>
            <ExternalLinkIcon />
          </SvgIcon>
        </Button>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(MoreInformationButtonStyle)(MoreInformationButton);
