import { connect } from "react-redux";

import { answerQuestion, endGame, startGame } from "../../actions";

import GameCard from "./GameCard";

const mapStateToProps = state => ({
  game: state.game,
  gameScore: state.gameScore,
  organizations: state.organizations
});

const mapDispatchToProps = dispatch => ({
  startGame: (player, organizationId) =>
    dispatch(startGame(player, organizationId)),
  answerQuestion: (gameId, answer) => dispatch(answerQuestion(gameId, answer)),
  endGame: gameId => dispatch(endGame(gameId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameCard);
