import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  withStyles
} from "@material-ui/core";

import ClosableDialogTitle from "./ClosableDialogTitle";
import { SubmittedAbbreviationDialogStyle } from "./SubmittedAbbreviationDialog.style";

class SubmittedAbbreviationDialog extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog onClose={this.props.finishSubmitting} open={true}>
        <ClosableDialogTitle
          id="customized-dialog-title"
          onClose={this.props.finishSubmitting}
        >
          Bedankt voor je Afko!
        </ClosableDialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content}>
            Je AFKO wordt zo snel mogelijk toegevoegd. Heb je een AFKO zonder
            betekenis ingevoerd dan proberen we de betekenis te achterhalen.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(SubmittedAbbreviationDialogStyle)(
  SubmittedAbbreviationDialog
);
