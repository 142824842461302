import React, { Component } from "react";
import ReactTimeout from "react-timeout";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  Hidden,
  Input,
  InputLabel,
  Select,
  Typography,
  withStyles
} from "@material-ui/core";

import { GameCardStyle } from "./GameCard.style";
import ResultsCard from "./ResultsCard";

import "./GameCard.sass";

const SECONDS_PER_ROUND = 60;
const STAGE_START = 0;
const STAGE_STARTING_GAME = 1;
const STAGE_PLAYING = 2;
const STAGE_FINISHING_GAME = 3;
const STAGE_FINISHED = 4;
const DEFAULT_PLAYER = "A. Noniem";

export class CardGameContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStage: STAGE_START,
      organizationId: undefined,
      player: ""
    };

    this.answer = this.answer.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.startGame = this.startGame.bind(this);
  }

  static getFirstOrganizationId(organizations) {
    if (organizations && organizations.length >= 1) {
      return organizations[0].id;
    }
    return 0;
  }

  decreaseCounter() {
    const secondsRemaining = this.state.secondsRemaining - 1;
    this.setState({
      secondsRemaining
    });
    if (secondsRemaining === 0) this.endGame();
  }

  endGame() {
    this.props.clearInterval(this.state.timer);
    this.setState({
      currentStage: STAGE_FINISHING_GAME
    });
    this.props.endGame(this.props.game.id);
  }

  startGame() {
    this.setState({
      currentStage: STAGE_STARTING_GAME,
      secondsRemaining: SECONDS_PER_ROUND,
      currentQuestion: null
    });
    this.props.startGame(
      this.state.player || DEFAULT_PLAYER,
      this.state.organizationId ||
        CardGameContainer.getFirstOrganizationId(this.props.organizations)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.newGameStarted(prevProps)) {
      this.setState({
        currentStage: STAGE_PLAYING,
        currentQuestion: 0,
        currentAnswer: "",
        timer: this.props.setInterval(this.decreaseCounter.bind(this), 1000)
      });
    } else if (this.state.currentStage === STAGE_FINISHING_GAME) {
      this.setState({
        currentStage: STAGE_FINISHED
      });
    } else if (this.props.organizations && !prevProps.organizations) {
      this.setState({
        organizationId: undefined
      });
    }
  }

  newGameStarted(prevProps) {
    return (
      this.state.currentStage === STAGE_STARTING_GAME &&
      this.props.game &&
      (!prevProps.game || this.props.game.id !== prevProps.game.id)
    );
  }

  nextQuestion() {
    this.props.answerQuestion(this.props.game.id, {
      question_order: this.state.currentQuestion + 1,
      answer: this.state.currentAnswer
    });
    if (this.props.game.questions.length === this.state.currentQuestion + 1) {
      this.endGame();
    } else {
      this.answerField.focus();
      this.setState({
        currentQuestion: this.state.currentQuestion + 1,
        currentAnswer: ""
      });
    }
  }

  answer(event) {
    this.setState({ currentAnswer: event.target.value });
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.gameCard}>
        <CardHeader title="AFKO Game" />
        <CardContent>{this.renderGameCardContents()}</CardContent>
        <CardActions className={this.props.classes.buttons}>
          {this.renderGameCardActions()}
        </CardActions>
      </Card>
    );
  }

  static renderStartingGame() {
    return <Typography>Vragen ophalen…</Typography>;
  }

  static renderFinishingGame() {
    return <Typography>Score bepalen…</Typography>;
  }

  renderGameCardContents() {
    switch (this.state.currentStage) {
      case STAGE_STARTING_GAME:
        return CardGameContainer.renderStartingGame();
      case STAGE_PLAYING:
        return this.renderPlaying();
      case STAGE_FINISHING_GAME:
        return CardGameContainer.renderFinishingGame();
      case STAGE_FINISHED:
        return this.renderFinished();
      default:
        return this.renderStart();
    }
  }

  renderGameCardActions() {
    const { currentStage } = this.state;
    const { classes } = this.props;
    if (currentStage === STAGE_PLAYING) {
      return (
        <Grid item>
          <Button
            id="next-question-button"
            variant="outlined"
            className={classes.button}
            onClick={this.nextQuestion}
          >
            Volgende
          </Button>
        </Grid>
      );
    } else if ([STAGE_START, STAGE_FINISHED].includes(currentStage)) {
      return (
        <Button
          id="start-game-button"
          variant="outlined"
          className={classes.button}
          onClick={this.startGame}
        >
          {currentStage === STAGE_FINISHED ? "Opnieuw" : "Start"}
        </Button>
      );
    }
  }

  renderFinished() {
    const { gameScore } = this.props;
    return <ResultsCard gameScore={gameScore} />;
  }

  renderStart() {
    const { classes } = this.props;
    return (
      <Grid container direction="row">
        <Grid item md={10}>
          <Grid container spacing={24}>
            <Grid item>
              <Typography className={classes.introOne} color="textSecondary">
                Raad zoveel mogelijk afkortingen in één minuut en word dé
                AFKO-kenner van de Rijksoverheid!
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.introTwo} color="textSecondary">
                Vul je naam en organisatie in om in de highscores te
                verschijnen.
              </Typography>
            </Grid>
            <Grid container spacing={16} className={classes.gameForm}>
              <Grid item>
                <FormControl>
                  <InputLabel htmlFor="player">Naam</InputLabel>
                  <Input
                    id="player"
                    name="player"
                    value={this.state.player}
                    onChange={this.handleFormChange}
                    autoFocus={true}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel htmlFor="organization">Organisatie</InputLabel>
                  {this.renderSelectOrganizations(this.props.organizations)}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown={true}>{this.renderScore()}</Hidden>
      </Grid>
    );
  }

  renderSelectOrganizations(organizations) {
    if (organizations) {
      return (
        <Select
          id="organization-id"
          name="organizationId"
          value={this.state.organizationId}
          native={true}
          onChange={this.handleFormChange}
        >
          <option value={undefined} />
          {organizations.map(org => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </Select>
      );
    } else {
      return <span>Geen organisaties geladen</span>;
    }
  }

  getPercent = () =>
    100 -
    ((SECONDS_PER_ROUND - this.state.secondsRemaining) / SECONDS_PER_ROUND) *
      100;

  renderPlaying() {
    return (
      <Grid container id="game-playing" direction="row">
        <Grid item md={10}>
          <Grid container spacing={8} direction="column">
            <Hidden mdDown={true}>{this.renderCountdownClock(150)}</Hidden>
            <Hidden lgUp={true}>
              <Grid
                container
                direction="row"
                spacing={8}
                justify={"space-around"}
              >
                {this.renderCountdownClock(100)}
                {this.renderScore()}
              </Grid>
            </Hidden>
            <Hidden mdDown={true}>
              <Grid item>
                <Grid container>
                  <Grid item md={6}>
                    {this.renderQuestion()}
                  </Grid>
                  <Grid item md={6}>
                    {this.renderAnswerLabel()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item md={6}>
                    {this.renderAbbreviation()}
                  </Grid>
                  <Grid item md={6}>
                    {this.renderAnswerField()}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp={true}>
              <Grid item>{this.renderQuestion()}</Grid>
              <Grid item>{this.renderAbbreviation()}</Grid>
              <Grid item>{this.renderAnswerLabel()}</Grid>
              <Grid item>{this.renderAnswerField()}</Grid>
            </Hidden>
            <Grid item>
              <Typography color="textSecondary">
                Als je het niet weet, laat het veld dan leeg en druk dan op
                Enter of klik of "Volgende"
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown={true}>{this.renderScore()}</Hidden>
      </Grid>
    );
  }

  renderAnswerField() {
    const { classes } = this.props;

    return (
      <FormControl>
        <Input
          id="answer"
          name="answer"
          inputRef={x => (this.answerField = x)}
          className={classes.answerField}
          value={this.state.currentAnswer}
          onChange={this.answer}
          autoFocus={true}
          onKeyDown={event => {
            if (event.key === "Enter") {
              this.nextQuestion();
            }
          }}
        />
      </FormControl>
    );
  }

  renderAbbreviation() {
    const { classes } = this.props;
    return (
      <Typography color="textSecondary" className={classes.abbreviation}>
        {this.props.game.questions[this.state.currentQuestion].abbreviation}
      </Typography>
    );
  }

  renderAnswerLabel() {
    return <InputLabel htmlFor="answer">Antwoord:</InputLabel>;
  }

  renderQuestion() {
    const { classes } = this.props;
    return (
      <Typography className={classes.form} color="textSecondary">
        Wat betekent de afko:
      </Typography>
    );
  }

  renderCountdownClock(size) {
    const { classes } = this.props;
    return (
      <Grid item id="seconds-left">
        <div className="progress" style={{ height: size }}>
          <div
            className="progress-text"
            style={{ width: size, fontSize: 0.13 * size, height: size }}
          >
            {this.state.secondsRemaining}
            <br />
            Seconden
            <CircularProgress
              variant="static"
              value={this.getPercent()}
              size={size}
              className={classes.progress}
              thickness={2.5}
              style={{ top: -0.33 * size }}
            />
          </div>
        </div>
      </Grid>
    );
  }

  renderScore() {
    const { classes, gameScore } = this.props;
    return (
      <Grid item id="score" md={2}>
        <Typography
          color="textSecondary"
          variant="h3"
          classes={{ h3: classes.scoreHeader }}
        >
          Score
        </Typography>
        <Typography
          color="textSecondary"
          variant="h3"
          classes={{ h3: classes.score }}
        >
          {gameScore ? gameScore.score : 0}
        </Typography>
      </Grid>
    );
  }
}

export default ReactTimeout(withStyles(GameCardStyle)(CardGameContainer));
