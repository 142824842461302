export const SearchAbbreviationCardStyle = theme => ({
  compactLastCard: {
    "&:last-child": {
      paddingBottom: 16,
      paddingTop: 0
    }
  },
  mostViewedButton: {
    color: "#E17000",
    fontSize: 12,
    minWidth: 0,
    textDecoration: "underline"
  },
  cardTitle: {
    fontSize: 16
  },
  inputLabel: {
    fontSize: 12,
    paddingLeft: 8
  },
  inputField: {
    fontSize: 20
  },
  inputFieldUnderline: {
    "&:after": {
      borderBottom: "2px solid rgb(240, 240, 254)"
    }
  },
  searchCard: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
      marginRight: 5
    },
    [theme.breakpoints.up("lg")]: {}
  },
  alignedWithLinks: {
    paddingLeft: 8
  }
});
