import { all, fork } from "redux-saga/effects";

import { watchSearchAbbreviations } from "./abbreviations";
import { watchAnswerQuestion, watchEndGame, watchStartGame } from "./games";
import { watchGetOrganizations } from "./organizations";
import { watchGetHighscores } from "./highscores";
import { watchGetViews } from "./views";

export default function* root() {
  yield all([
    watchEndGame(),
    watchGetHighscores(),
    watchGetOrganizations(),
    watchGetViews(),
    watchStartGame(),
    watchAnswerQuestion(),
    fork(watchSearchAbbreviations)
  ]);
}
