import axios from "axios";

const RESOURCE_ABBREVIATIONS = "abbreviations/";
const RESOURCE_GAMES = "games/";
const RESOURCE_HIGHSCORES = "highscores/";
const RESOURCE_ORGANIZATIONS = "organizations/";
const RESOURCE_VIEWS = "views/";

const apiUrl = resource => `${process.env.REACT_APP_API_URL}/${resource}`;

const apiFetch = async (resource, params = {}) =>
  axios({
    method: "GET",
    url: apiUrl(resource),
    params
  });

const apiPost = async (resource, data = {}) => apiWrite("POST", resource, data);
const apiPut = async (resource, data = {}) =>
  apiWrite("PUT", `${resource}${data.id}`, data);

const apiWrite = async (method, resource, data = {}, url = apiUrl(resource)) =>
  axios({ method, url, data });

export default {
  getAbbreviations: async params =>
    await apiFetch(RESOURCE_ABBREVIATIONS, params),
  postAbbreviation: async data => await apiPost(RESOURCE_ABBREVIATIONS, data),
  putAbbreviation: async data => await apiPut(RESOURCE_ABBREVIATIONS, data),
  endGame: async id => await apiPut(RESOURCE_GAMES, { id, finished: true }),
  answerQuestion: async (id, answer) =>
    await apiPut(RESOURCE_GAMES, { id, answer }),
  getHighscores: async () => await apiFetch(RESOURCE_HIGHSCORES),
  getOrganizations: async () => await apiFetch(RESOURCE_ORGANIZATIONS),
  getViews: async () => await apiFetch(RESOURCE_VIEWS),
  startGame: async (player, organization_id) =>
    await apiPost(RESOURCE_GAMES, { player, organization_id }),
  view: async id => await apiPost(RESOURCE_VIEWS, { id })
};
