import { connect } from "react-redux";

import { getViews, searchAbbreviations, updateSearch } from "../../actions";

import SearchAbbreviationCard from "./SearchAbbreviationCard";

const mapStateToProps = ({ search, searchedAbbreviations, views }) => ({
  search,
  searchedAbbreviations,
  views
});

const mapDispatchToProps = dispatch => ({
  searchAbbreviations: search => {
    dispatch(updateSearch(search));
    dispatch(searchAbbreviations(search));
  },
  getViews: () => dispatch(getViews())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAbbreviationCard);
