import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import * as actions from "../actions";

export function* getOrganizations(action) {
  const { data, error } = yield call(api.getOrganizations);
  yield put(actions.receiveOrganizations({ data, error }));
}

export function* watchGetOrganizations() {
  yield takeLatest(actions.GET_ORGANIZATIONS, getOrganizations);
}
