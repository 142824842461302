import React, { Component } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Obfuscate from "react-obfuscate";
import {
  AppBar,
  Grid,
  Hidden,
  Typography,
  withStyles,
  withWidth
} from "@material-ui/core";

import { MAIL_ADDRESS } from "../constants/mail";

import AfkotheekContainer from "./afkotheek/AfkotheekContainer";
import SubmitAbbreviationContainer from "./indienen/SubmitAbbreviationContainer";
import ReactiveGameContainer from "./game/ReactiveGameContainer";
import NavigationTop from "./shared/NavigationTop";
import "./App.sass";
import NavigationMenu from "./shared/NavigationMenu";
import { ReactComponent as ROLogo } from "./logo-rijksoverheid.svg";
import { AppStyle } from "./App.style";

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="column">
        <Grid item xs={12} classes={{ item: classes.fixItemForIE }}>
          {this.renderHeader()}
        </Grid>
        <Grid item xs={12} classes={{ item: classes.main }}>
          {App.renderRoutes()}
        </Grid>
        <Hidden smDown={true}>
          <Grid item xs={12} classes={{ item: classes.fixItemForIE }}>
            {this.renderFooter()}
          </Grid>
        </Hidden>
      </Grid>
    );
  }

  renderHeader() {
    return (
      <Grid container direction="column" alignItems="center" className="header">
        <Hidden smDown>
          <Grid item>
            <ROLogo />
          </Grid>
        </Hidden>
        <Grid item className="app-bar-container">
          {this.renderAppBar()}
        </Grid>
      </Grid>
    );
  }

  renderAppBar() {
    const { classes } = this.props;
    return (
      <AppBar position="relative" className={classes.noTopShadowAppBar}>
        <Grid container alignItems="flex-end">
          <Hidden mdUp>
            <NavigationMenu />
          </Hidden>
          <Grid item classes={{ item: classes.siteTitleGridItem }}>
            {this.renderSiteTitle()}
          </Grid>
          <Hidden smDown>
            <Grid item>
              <NavigationTop />
            </Grid>
          </Hidden>
        </Grid>
      </AppBar>
    );
  }

  renderSiteTitle() {
    const { classes } = this.props;
    return (
      <Link to="/" className="site-title-link">
        <Typography
          variant={this.getHeaderVariant()}
          classes={{ h1: classes.siteTitle, h4: classes.siteTitle }}
        >
          AFKO.
          <Typography
            variant={this.getTagLineVariant()}
            classes={{ body2: classes.tagLine, caption: classes.tagLine }}
          >
            Afkortingen binnen de Rijksoverheid
          </Typography>
        </Typography>
      </Link>
    );
  }

  renderFooter() {
    const { classes } = this.props;
    return (
      <div className="footer">
        <Typography variant="caption" classes={{ caption: classes.footerText }}>
          AFKO is ontwikkeld door <a href="https://www.p-direkt.nl">P-Direkt</a>
          . Vragen of opmerkingen?{" "}
          <Obfuscate email={MAIL_ADDRESS}>Mail</Obfuscate> ons.
        </Typography>
      </div>
    );
  }

  static renderRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={AfkotheekContainer} />
        <Route exact path="/game" component={ReactiveGameContainer} />
        <Route exact path="/indienen" component={SubmitAbbreviationContainer} />
        <Redirect to="/" />
      </Switch>
    );
  }

  getHeaderVariant() {
    return this.props.width === "sm" || this.props.width === "xs" ? "h4" : "h1";
  }

  getTagLineVariant() {
    return this.props.width === "sm" || this.props.width === "xs"
      ? "caption"
      : "body2";
  }
}

export default withWidth()(withStyles(AppStyle)(App));
