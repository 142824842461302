import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"ROsansweb", "sans-serif"'].join(","),
    fontSize: 12,
    h6: {
      color: "#717171",
      fontSize: 12,
      fontWeight: "bold"
    }
  },
  overrides: {
    MuiCardHeader: {
      title: {
        fontSize: 16
      }
    }
  },
  palette: {
    primary: {
      main: "#ffffff",
      contrastText: "#717171"
    },
    secondary: {
      main: "#e17000"
    }
  }
});
