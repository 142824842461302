import { connect } from "react-redux";

import {
  finishSubmitting,
  getOrganizations,
  submissionFailed,
  submissionSuccess
} from "../../actions";
import api from "../../api";
import { DEFAULT_ABBREVIATION_DOMAIN } from "../../constants/abbreviation";

import SubmitAbbreviationCard from "./SubmitAbbreviationCard";

// Grabs the domainName from `organizations` (name)
// based on the domainId present in `submittedAbbreviation`
const decorateWithDomainName = (submittedAbbreviation, organizations) => {
  const { abbreviation } = submittedAbbreviation;
  if (abbreviation) {
    abbreviation.domainName =
      (
        (organizations || []).find(
          org => org.id === abbreviation.organization
        ) || {}
      ).name || DEFAULT_ABBREVIATION_DOMAIN;
  }
  return submittedAbbreviation;
};

const mapStateToProps = state => {
  const { suggestedImprovement, submittedAbbreviation, organizations } = state;

  return {
    suggestedImprovement,
    submittedAbbreviation: decorateWithDomainName(
      submittedAbbreviation,
      organizations
    ),
    organizations
  };
};

const mapDispatchToProps = dispatch => ({
  finishSubmitting: () => dispatch(finishSubmitting()),
  getOrganizations: () => dispatch(getOrganizations()),
  submitAbbreviation: async abbreviation =>
    api["postAbbreviation"](abbreviation)
      .then(() => {
        dispatch(submissionSuccess(abbreviation));
      })
      .catch(err => dispatch(submissionFailed(err)))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitAbbreviationCard);
