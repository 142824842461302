import { combineReducers } from "redux";

import game from "./game";
import gameScore from "./gameScore";
import searchedAbbreviations from "./searchedAbbreviations";
import submittedAbbreviation from "./submittedAbbreviation";
import organizations from "./organizations";
import highscores from "./highscores";
import views from "./views";
import search from "./search";
import loading from "./loading";

export default combineReducers({
  game,
  gameScore,
  highscores,
  loading,
  organizations,
  searchedAbbreviations,
  submittedAbbreviation,
  search,
  views
});
