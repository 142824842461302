import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  withStyles
} from "@material-ui/core";

import SubmitAbbreviationForm from "./SubmitAbbreviationForm";
import SubmittedAbbreviationDialog from "./SubmittedAbbreviationDialog";
import { SubmitAbbreviationCardStyle } from "./SubmitAbbreviationCard.style";

class SubmitAbbreviationCard extends Component {
  componentDidMount() {
    this.props.getOrganizations();
    // If the component is mounted while `submittedAbbreviation.abbreviation` exists,
    // it means the route was changed (i.e. via user navigation), and
    // upon re-initialisation of the component, we should explicitly call
    // `finishSubmitting` in order to render a pristine input form.
    if (this.props.submittedAbbreviation.abbreviation)
      this.props.finishSubmitting();
  }
  render() {
    const {
      finishSubmitting,
      suggestedImprovement,
      history,
      submitAbbreviation,
      submittedAbbreviation,
      organizations,
      classes
    } = this.props;

    const { abbreviation, error } = submittedAbbreviation || {};
    return (
      <Card
        data-cy="submission-card"
        className={classes.submitAbbreviationCard}
      >
        <CardHeader title="Een nieuwe AFKO indienen" />
        <CardContent>
          {error && (
            <Typography color="error">Fout: {error.message}</Typography>
          )}
          {!abbreviation && (
            <SubmitAbbreviationForm
              suggestedImprovement={suggestedImprovement}
              history={history}
              submitAbbreviation={submitAbbreviation}
              organizations={organizations}
            />
          )}
          {abbreviation && (
            <SubmittedAbbreviationDialog finishSubmitting={finishSubmitting} />
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(SubmitAbbreviationCardStyle)(SubmitAbbreviationCard);
