export const GameCardStyle = theme => ({
  progress: {
    color: "rgb(163,161,251)",
    position: "absolute",
    left: 0
  },
  score: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 35
  },
  scoreHeader: {
    fontSize: 35,
    textAlign: "center"
  },
  introOne: {
    fontSize: 20
  },
  introTwo: {
    fontSize: 14
  },
  gameForm: {
    paddingLeft: 12
  },
  button: {
    textTransform: "none",
    color: "#717171"
  },
  buttons: {
    justifyContent: "flex-end"
  },
  abbreviation: {
    fontSize: 35,
    fontWeight: "bold"
  },
  form: {
    fontSize: 14
  },
  answerField: {
    fontSize: 35
  },
  gameCard: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
      marginRight: 5
    }
  }
});
