import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import ReactiveGameCard from "./ReactiveGameCard";
import ReactiveHighScoresCard from "./ReactiveHighScoresCard";

class GameContainer extends Component {
  componentDidMount() {
    this.props.getOrganizations();
  }

  render() {
    return (
      <Grid container justify="center" spacing={16}>
        <Grid item xs={12} md={5}>
          <ReactiveGameCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReactiveHighScoresCard />
        </Grid>
      </Grid>
    );
  }
}

export default GameContainer;
