export const SubmitAbbreviationFormStyle = theme => ({
  note: {
    color: "#A4AFB7"
  },
  submitButton: {
    textTransform: "none"
  },
  form: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textField: {
    width: 200
  }
});
