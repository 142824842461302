import React, { Component } from "react";
import {
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
  withWidth
} from "@material-ui/core";

import "./ResultsCard.sass";
import MoreInformationButton from "../shared/MoreInformationButton";

import { ResultsCardStyle } from "./ResultsCard.style";

class ResultsCard extends Component {
  static ROWS_PER_PAGE = 5;
  state = {};

  componentDidMount() {
    this.setState({
      page: 0
    });

    this.handlePageChanged = this.handlePageChanged.bind(this);
  }

  static getTextForScore(score) {
    if (score === 0) {
      return "0 punten. Gelukkig heeft niemand het gezien. Probeer het snel nog eens.";
    } else if (score === 1) {
      return "1 punt. Beter dan niks, maar daar is alles dan wel mee gezegd. Nieuwe poging?";
    } else if (score <= 5) {
      return `${score} punten, dat kan beter! Speel nog eens!`;
    } else if (score <= 10) {
      return `Je hebt ${score} punten gescoord, niet verkeerd. Ben je klaar voor een highscore?`;
    } else if (score < 60) {
      return `Netjes, je hebt ${score} punten gescoord! Kun je nog beter?`;
    } else if (score === 60) {
      return `Wow, alles goed! Klaar voor je dopingtest?`;
    }
  }

  getValueInCell = (row, colDefinition) =>
    typeof colDefinition === "function"
      ? colDefinition(row)
      : row[colDefinition];

  handlePageChanged(page) {
    this.setState({
      page
    });
  }

  render() {
    const { classes } = this.props;
    const { answers, score } = this.props.gameScore || {};

    return (
      <Grid container id="game-finished" direction="column" spacing={16}>
        <Grid item>
          <Typography className={classes.score} color="textSecondary">
            {ResultsCard.getTextForScore(score)}
          </Typography>
        </Grid>
        {answers && answers.length > 0 && (
          <Grid item>
            {this.renderAnswersTable(answers)}
            {this.renderAnswersTablePagination()}
          </Grid>
        )}
      </Grid>
    );
  }

  renderAnswersTable(answers) {
    const { classes, width } = this.props;

    const { page } = this.state;
    const filterCurrentPageRows = (_, i) =>
      i >= ResultsCard.ROWS_PER_PAGE * page &&
      i < ResultsCard.ROWS_PER_PAGE * (page + 1);

    return (
      <Table padding={width === "xl" ? "default" : "none"}>
        {this.getTableHead()}
        <TableBody>
          {answers.filter(filterCurrentPageRows).map((answer, i) => (
            <TableRow key={i} className={classes.answerRow}>
              <Hidden lgDown={true}>
                <TableCell>{answer.questionOrder}</TableCell>
              </Hidden>
              <TableCell className={classes.abbreviation}>
                {answer.correctAnswer.abbreviation}
              </TableCell>
              <Hidden mdDown={true}>
                <TableCell
                  className={
                    answer.points > 0 ? classes.correct : classes.incorrect
                  }
                >
                  {answer.givenAnswer}
                </TableCell>
              </Hidden>
              <TableCell className={classes.definition}>
                {answer.correctAnswer.definitions.map(definition => (
                  <span key={definition.fullText}>
                    {definition.fullText}
                    <Typography
                      variant="body1"
                      color="secondary"
                      className={classes.organizationNames}
                    >
                      {definition.organizations.map(org => org.name).join(", ")}
                      <MoreInformationButton url={definition.url} />
                    </Typography>
                  </span>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  getTableHead() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <Hidden lgDown={true}>
            <TableCell className={classes.th} key="nr">
              #
            </TableCell>
          </Hidden>
          <TableCell className={classes.th} key="afko">
            Afko
          </TableCell>
          <Hidden mdDown={true}>
            <TableCell className={classes.th} key="answer">
              Geantwoord
            </TableCell>
          </Hidden>
          <TableCell className={classes.th} key="fullText">
            Betekenis
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderAnswersTablePagination() {
    return (
      <TablePagination
        nextIconButtonProps={{ id: "next-search-page" }}
        backIconButtonProps={{ id: "prev-search-page" }}
        component="div"
        count={((this.props.gameScore || {}).answers || []).length}
        page={this.state.page}
        rowsPerPage={ResultsCard.ROWS_PER_PAGE}
        rowsPerPageOptions={[]}
        onChangePage={(_, page) => this.handlePageChanged(page)}
      />
    );
  }
}

export default withWidth()(withStyles(ResultsCardStyle)(ResultsCard));
