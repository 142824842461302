import "react-app-polyfill/ie11";
import entries from "object.entries";
import includes from "array-includes";
import values from "object.values";
import "string.prototype.repeat";
/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, withRouter } from "react-router-dom";

import "./index.sass";
import App from "./components/App";
import { configureStore } from "./store";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { theme } from "./mui-theme";

// Activating these before any other imports in case they're used
if (!Object.entries) {
  entries.shim();
}
if (!Array.includes) {
  includes.shim();
}
if (!Object.values) {
  values.shim();
}

/* eslint-enable */

const store = configureStore();

const InitializingApp = withRouter(App);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <InitializingApp />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
