import { connect } from "react-redux";

import { getHighscores } from "../../actions";

import HighScoresCard from "./HighScoresCard";

const mapStateToProps = state => ({
  highscores: state.highscores
});

const mapDispatchToProps = dispatch => ({
  getHighscores: () => dispatch(getHighscores())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighScoresCard);
