import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, withStyles } from "@material-ui/core";

import { ROUTES } from "../../constants/routes";

import { NavigationTopStyle } from "./NavigationTop.style";

class NavigationTop extends Component {
  handleTabSelectionChange = (_, selectedPath) =>
    this.props.history.push(selectedPath);

  render() {
    const { location } = this.props.history;
    const { classes } = this.props;

    return (
      <Tabs
        value={location.pathname}
        onChange={this.handleTabSelectionChange}
        variant="fullWidth"
      >
        {Object.entries(ROUTES).map((tab, i) => (
          <Tab
            classes={{
              selected: classes.selectedTab,
              root: i === ROUTES.length - 1 ? classes.tab : classes.lastTab
            }}
            key={i}
            label={tab[0]}
            value={tab[1]}
            data-cy={`route${tab[1]}`}
          />
        ))}
      </Tabs>
    );
  }
}

export default withRouter(withStyles(NavigationTopStyle)(NavigationTop));
