export const NavigationTopStyle = () => ({
  tab: {
    borderLeft: "1px solid rgb(240, 240, 254)",
    color: "#1D1D1B",
    fontSize: 14
  },
  lastTab: {
    fontSize: "14px",
    borderRight: "1px solid rgb(240, 240, 254)"
  },
  selectedTab: {
    fontWeight: "bold",
    backgroundColor: "rgba(255, 170, 29, 0.11)"
  }
});
