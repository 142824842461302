export const AbbreviationsCardStyle = theme => ({
  compactCardHeader: {
    paddingBottom: 8
  },
  compactCardContent: {
    height: 435,
    overflow: "auto"
  },
  abbreviationCell: {
    verticalAlign: "top",
    fontSize: 20,
    paddingTop: 0,
    color: "#717171",
    maxWidth: 250
  },
  linkCell: {
    verticalAlign: "bottom"
  },
  organizationNames: {
    fontSize: 12
  },
  abbreviationsCard: {
    [theme.breakpoints.up("lg")]: {
      height: 570
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      marginLeft: 5,
      marginRight: 5
    }
  },
  organizationSelect: {
    marginTop: -5,
    marginLeft: 10
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }
});
