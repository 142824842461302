import React, { Component } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { withFormsy } from "formsy-react";

import {
  DEFAULT_ABBREVIATION_DOMAIN,
  DEFAULT_DOMAIN_KEY
} from "../../constants/abbreviation";

class AfkoSelect extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    return (
      <FormControl key={1} className={this.props.className}>
        <InputLabel
          shrink
          htmlFor={this.props.name}
          required={this.props.required}
        >
          {this.props.label}
        </InputLabel>
        <Select
          name={this.props.name}
          native
          value={this.props.getValue() || DEFAULT_DOMAIN_KEY}
          onChange={this.changeValue}
          inputProps={{
            id: this.props.id,
            name: this.props.name
          }}
        >
          <option key={DEFAULT_DOMAIN_KEY} value={DEFAULT_DOMAIN_KEY}>
            {DEFAULT_ABBREVIATION_DOMAIN}
          </option>
          {this.props.options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withFormsy(AfkoSelect);
