import { call, delay, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import * as actions from "../actions";

export function* searchAbbreviations(action) {
  yield put(actions.startLoading());
  yield delay(500);
  const params = action.params;
  if (params.q) {
    params.q = params.q.trim();
  }
  if (params.from === "") {
    delete params.from;
  }
  if (params.to === "") {
    delete params.to;
  }
  if (params.organization_id === "") {
    delete params.organization_id;
  }
  if (params.q === "" && !params.to && !params.from) {
    yield put(
      actions.receiveAbbreviations({
        data: { entries: 0, results: [] },
        error: {}
      })
    );
  } else {
    const { data, error } = yield call(api.getAbbreviations, params);
    yield put(actions.receiveAbbreviations({ data, error }));
  }
  yield put(actions.stopLoading());
}

export function* watchSearchAbbreviations() {
  yield takeLatest(actions.SEARCH_ABBREVIATIONS, searchAbbreviations);
}
