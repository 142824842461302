import { SUBMISSION_FAILED, SUBMISSION_SUCCESS } from "../actions";

const INIT_LAST_SUBMISSION = {
  abbreviation: null,
  error: null
};

export default (
  state = INIT_LAST_SUBMISSION,
  { abbreviation, error, type }
) => {
  switch (type) {
    case SUBMISSION_FAILED:
      return { error };
    case SUBMISSION_SUCCESS:
      return { abbreviation };
    default:
      return state;
  }
};
