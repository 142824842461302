import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { HighScoresCardStyle } from "./HighScoresCard.style";
import { ReactComponent as AwardIcon } from "./Award.svg";

const SUFFIXES = ["First", "Second", "Third", "", "", "", "", "", "", ""];

class HighScoresCard extends Component {
  constructor(props) {
    super(props);
    this.props.getHighscores();
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.highscoresCard}>
        <CardHeader title="High scores" />
        <CardContent>
          <Table padding="checkbox">
            <TableBody
              children={(this.props.highscores || []).map((highscore, index) =>
                this.renderRow(index, highscore)
              )}
            />
          </Table>
        </CardContent>
      </Card>
    );
  }

  renderRow(index, highscore) {
    const { classes } = this.props;

    const suffix = SUFFIXES[index];

    return (
      <TableRow key={index}>
        <TableCell className={classes.rank} align="center">
          {index < 3 ? (
            <SvgIcon className={classes["award" + suffix]}>
              <AwardIcon />
            </SvgIcon>
          ) : (
            index + 1
          )}
        </TableCell>
        <TableCell className={classes["highscores" + suffix]}>
          {highscore.name}
          <Typography className={classes.organization}>
            {highscore.organization}
          </Typography>
        </TableCell>
        <TableCell align="right" className={classes["highscores" + suffix]}>
          {highscore.score}
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(HighScoresCardStyle)(HighScoresCard);
