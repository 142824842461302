import React, { Component } from "react";
import Obfuscate from "react-obfuscate";
import Formsy from "formsy-react";
import { Button, Hidden, Typography, withStyles } from "@material-ui/core";

import { DEFAULT_ABBREVIATION_INPUT } from "../../constants/abbreviation";
import { MAIL_ADDRESS } from "../../constants/mail";

import AfkoTextField from "./AfkoTextField";
import AfkoSelect from "./AfkoSelect";
import "./SubmitAbbreviationForm.sass";
import { SubmitAbbreviationFormStyle } from "./SubmitAbbreviationForm.style";

class SubmitAbbreviationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: DEFAULT_ABBREVIATION_INPUT,
      canSubmit: false
    };
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.props.history.goBack();
  }

  async handleSubmit(model) {
    const { id } = this.props.suggestedImprovement || {};

    await this.setState({
      input: {
        ...this.state.input,
        ...model
      }
    });

    this.props.submitAbbreviation({
      id,
      ...this.state.input
    });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  render() {
    const { classes } = this.props;
    return (
      <Formsy
        onValidSubmit={this.handleSubmit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        className={classes.form}
      >
        {this.renderFormFields()}
        {
          <div className="submit-buttons">
            <Button
              data-cy="submit-button"
              variant="outlined"
              type="submit"
              disabled={!this.state.canSubmit}
              className={classes.submitButton}
            >
              Verstuur
            </Button>
          </div>
        }
        <Hidden mdDown={true}>
          <Typography className={classes.note}>
            Heb je meerdere afkortingen of lijsten met afkortingen?{" "}
            <Obfuscate email={MAIL_ADDRESS}>Mail</Obfuscate> ons.
          </Typography>
        </Hidden>
      </Formsy>
    );
  }
  renderFormFields() {
    const { classes } = this.props;
    return (
      <div className="submit-form">
        <AfkoTextField
          autoFocus={true}
          className={classes.textField}
          id="input-afko-abbreviation"
          name="abbreviation"
          label="Afkorting"
          required
          validationError="Ongeldige afko"
          value={this.state.input["abbreviation"]}
        />
        <AfkoTextField
          id="input-afko-fullText"
          className={classes.textField}
          name="fullText"
          label="Betekenis"
          validationError="Ongeldige betekenis"
          value={this.state.input["fullText"]}
        />
        <Typography className={classes.note}>
          Als je de betekenis niet weet kan dit veld leeg gelaten worden.
        </Typography>
        <AfkoTextField
          id="input-afko-url"
          className={classes.textField}
          name="url"
          label="Externe link"
          value={this.state.input["url"]}
        />
        <AfkoSelect
          id="input-afko-organization"
          name="organization"
          label="In gebruik"
          required
          options={this.props.organizations || []}
          value={this.state.input["organization"]}
        />
      </div>
    );
  }
}

export default withStyles(SubmitAbbreviationFormStyle)(SubmitAbbreviationForm);
