import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import ReactiveAbbreviations from "./ReactiveAbbreviationsCard";
import ReactiveSearchAbbreviationCard from "./ReactiveSearchAbbreviationCard";

class AfkotheekContainer extends Component {
  render() {
    return (
      <Grid container justify="center">
        <Grid item xs={12} lg={8} xl={6}>
          <Grid container justify="center">
            <Grid item xs={12} lg={6}>
              <ReactiveSearchAbbreviationCard />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ReactiveAbbreviations type="afko" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default AfkotheekContainer;
